import React from 'react';
import { Helmet } from 'react-helmet';
import { Box } from '@chakra-ui/core';
import NavBar from './NavBar';

const Layout = ({ children }) => {
  return (
    <>
      <Helmet defaultTitle="Michael Mao | Full-Stack Web Developer">
        <html lang="en" />
        <meta name="description" content="Michael Mao is a full-stack web developer based in Toronto, Canada." />
      </Helmet>
      <NavBar />
      <Box as="main" maxWidth={960} mx="auto" px={6}>
        {children}
      </Box>
    </>
  );
};

export default Layout;
