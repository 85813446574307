import React from 'react';
import { IconButton, useColorMode } from '@chakra-ui/core';

const DarkModeToggle = () => {
  const { colorMode, toggleColorMode } = useColorMode();

  const isDarkMode = colorMode === 'dark';

  return (
    <IconButton
      aria-label="Toggle dark mode"
      variant="ghost"
      fontSize="xl"
      icon={isDarkMode ? "sun" : "moon"}
      onClick={toggleColorMode}
    />
  );
};

export default DarkModeToggle;
