import React from 'react';
import { Link as RouterLink } from 'gatsby';
import { Flex, Link, Stack } from '@chakra-ui/core';
import DarkModeToggle from './DarkModeToggle';

const NavBar = () => {
  return (
    <Flex alignItems="center" justifyContent="flex-end" p={2}>
      <Stack flexGrow="1" justifyContent="flex-end" spacing={6} mr={24} isInline>
        <Link as={RouterLink} to="/about">
          About
        </Link>
        <Link href="//blog.michaelmao.ca" target="_blank" rel="noopener">
          Blog
        </Link>
      </Stack>
      <DarkModeToggle />
    </Flex>
  );
};

export default NavBar;
